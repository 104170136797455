<template>
  <div class="siwow">
    <img class="logo" src="../../assets/siwow/wow33-static-png.png" />
    <div class="badge">AKUN PRO TERBAIK</div>
    <div class="_h1">MEMBER PASTI DIMANJA DISINI!</div>
    <img class="gameImg" src="../../assets/siwow/game-treasure-of-aztec.png" />
    <div class="_p">BARU DENGAN TAMPILAN MODIS | SELALU TERDEPAN</div>
    <div class="container">
      <div class="text-center" @click="toUrl()">
        <a
          id="neonShadow"
          class="btn daftar d-flex align-items-center justify-content-center"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-person-plus-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
            ></path>
            <path
              fill-rule="evenodd"
              d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
            ></path>
          </svg>
          KLIK DI SINI</a
        >
      </div>
      <div class="text-center">
        <p class="mt-5">Tampilan Paling Keren !!!</p>
      </div>
    </div>
    <div class="bottom_text1" @click="toUrl()">Privacy Policy</div>
    <div class="bottom_text2" @click="toUrl()">Hubungi Kami</div>
    <div class="bottom_text3">2024 ALL RIGHTS RESERVED</div>
  </div>
</template>

<script>
import { Row, Col, Icon, Cell, Swipe, SwipeItem, Popup, Loading } from "vant";
export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Loading.name]: Loading,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toUrl() {
      window.location.href = sessionStorage["url"];
    },
  },
};
</script>

<style lang="less">
@media screen and (min-width: 1000px) {
  .siwow {
    transform: scale(0.5);
    transform-origin: top;
  }
}
.siwow {
  text-align: center;
  .logo {
    width: 216px;
    margin: 25px 0;
  }
  .badge {
    font-family: "Arial";
    font-size: 16px;
    background: #000;
    color: gold;
    border: solid 1px gold;
    padding: 8px 24px;
    white-space: normal;
    border-radius: 160px;
    font-weight: 700;
    line-height: 1;
    display: inline-block;
    margin-bottom: 16px;
    letter-spacing: 3.2px;
  }
  ._h1 {
    color: #ff0;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 16px 0;
    text-transform: uppercase;
    letter-spacing: 3.2px;
    font-size: 22.4px;
    padding: 0 20px;
  }
  .gameImg {
    width: 345px;
    margin-bottom: 16px;
  }
  ._p {
    margin-bottom: 16px;
  }
  .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    .text-center {
      .daftar {
        box-sizing: border-box;
        display: inline-block;
        width: 345px;
        border-radius: 5px;
        padding: 16px 0px 16px 0px;
        margin: 10px auto;
        font-weight: 700;
        font-size: 16px;
        font-family: Lato, Arial, sans-serif;
        text-transform: uppercase;
        text-decoration: none;
        will-change: transform;
        line-height: 12px;
        box-shadow: 0 0 15px #560e0e;
      }
      #neonShadow {
        border: 1px solid #000;
        transition: 0.3s;
        animation: glow 1s infinite;
        transition: 0.5s;
        background-color: gold;
        color: #000000;
        align-items: center !important;
        justify-content: center !important;
        display: flex !important;
        svg {
          display: inline-block;
          padding-right: 10px;
        }
      }
      @keyframes glow {
        0% {
          box-shadow: 5px 5px 20px rgb(255, 0, 0), -5px -5px 20px rgb(255, 0, 0);
        }

        50% {
          box-shadow: 5px 5px 20px rgb(255, 40, 139),
            -5px -5px 20px rgb(255, 40, 139);
        }

        100% {
          box-shadow: 5px 5px 20px rgb(255, 0, 0), -5px -5px 20px rgb(255, 0, 0);
        }
      }
      p {
        margin-top: 48px;
        margin-bottom: 16px;
      }
    }
  }
  .bottom_text1 {
    padding-top: 16px;
    color: #ff1111;
    line-height: 21.12px;
    font-size: 12.8px;
  }
  .bottom_text2 {
    color: #ff1111;
    line-height: 21.12px;
    font-size: 12.8px;
  }
  .bottom_text3 {
    padding-bottom: 16px;
  }
}
</style>
